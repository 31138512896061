import React, { FC, useState, useEffect, useRef } from "react"

import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl"
import { StaticImage } from "gatsby-plugin-image"
import Slider from 'react-slick'
import { Animated } from "react-animated-css";
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
    >
      <i className="icon-arrow-right-outline"></i>
    </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
    >
      <i className="icon-arrow-right-outline"></i>
    </div>
  );
}

export const Navigation: FC<{ data: any }> = ({ data }) => {
  const intl = useIntl();
  const [navState, setNavState] = useState('')

  let activeIndex = 0;

  if (typeof window !== `undefined`) {
    activeIndex = data?.allContentfulTextpage.edges.findIndex(element => element.node.slug == window.location.pathname.replace('/de/', ''))
  }

  if (activeIndex < 0) {
    activeIndex = 0
  }

  const slider = useRef();

  const settings = {
    arrows: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    rows: 1,
    focusOnSelect: false,
    swipeToSlide: true,
    initialSlide: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />
  }

  const toggleNavigation = () => {
    if (navState) {
      document.body.classList.remove("nav--open");
      setNavState('');
      return
    }

    document.body.classList.add("nav--open");
    setNavState('is--open');
  };


  const closeNavigation = () => {
    document.body.classList.remove("nav--open");
    setNavState('');
  };

  return (
    <div className={`nav-outer ${navState}`}>
      <div className="container">
        <div className="d-flex align-items-center">
          <button onClick={toggleNavigation} className={`nav-toggle is--close-variant ${navState}`}>
            <StaticImage alt="close" src="../../images/close.png" />
            <span>{intl.formatMessage({ id: "nav_close" })}</span>
          </button>
          <button onClick={() => { toggleNavigation(); slider.current.slickGoTo(activeIndex) }} className={`nav-toggle is--open-variant ${navState}`}>
            <i className="icon-menu"></i>
            <span>{intl.formatMessage({ id: "nav_open" })}</span>
          </button>
        </div>

        {intl.locale == 'de' &&
          <Link to="/" className="nav-brand">
            <StaticImage alt="brand" src="../../images/logo.svg" />
          </Link>
        }
        {intl.locale == 'en' &&
          <Link to="/home" className="nav-brand">
            <StaticImage alt="brand" src="../../images/logo.svg" />
          </Link>
        }

        <div className="d-flex justify-content-end align-items-center">
          <div className="nav-switcher">
            <a href="/en/home" className={intl.locale != 'en' ? 'text-white' : ''}>
              EN
            </a>
            |
            <a href="/de" className={intl.locale != 'de' ? 'text-white' : ''}>
              DE
            </a>
          </div>

          {intl.locale == 'de' &&
            <a href="https://onepagebooking.com/oldschool-soell" className="nav-cta" target="_blank">
              <p className="nav-cta-title">
                STOP looking -<br /> START booking!
              </p>
              <StaticImage alt="book" src="../../images/orange-stroke.png" />
            </a>
          }
          {intl.locale == 'en' &&
            <a href="https://onepagebooking.com/oldschool-soell?module=public&ratetype=bar&lang=en" className="nav-cta" target="_blank">
              <p className="nav-cta-title">
                STOP looking -<br /> START booking!
              </p>
              <StaticImage alt="book" src="../../images/orange-stroke.png" />
            </a>
          }
        </div>
      </div>

      <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration={500} animationOutDuration={500} isVisible={navState == 'is--open'} animateOnMount={false}>
        <div className="nav-links">
          <div className="container">
            <Slider ref={slider} className="nav-links-slider" {...settings}>
              {data?.allContentfulTextpage && data.allContentfulTextpage.edges.filter(node => node.node.navigationImage).map(link => (
                <div key={'navigation-link' + link.node.id} className="nav-links-entry">
                  <Link onClick={closeNavigation} to={'/' + (link.node.slug == '/home' ? 'home' : link.node.slug)} >
                    <div className="nav-links-entry-name"> {link.node.navigationName}</div>
                    <div className="nav-links-entry-text">  {link.node.navigationText}</div>
                    {link.node.navigationImage &&
                      <img className="img-fluid" src={link.node.navigationImage.fixed.src} alt={link.node.navigationName} />
                    }
                  </Link>
                </div>
              ))}
            </Slider>
          </div>
          <div className="nav-links-bar">
            {data?.allContentfulTextpage.edges.filter((element, index) => index > 0 && index <= 4).map(link => (
              <Link key={'navigation-entry' + link.node.id} onClick={closeNavigation} activeClassName="active" to={'/' + link.node.slug} >{link.node.name}</Link>
            ))}
          </div>
          <div className="nav-links-bg">
            <StaticImage alt="nav bg" src="../../images/nav-bg.png" />
          </div>
        </div>
      </Animated>
    </div>
  )
}
